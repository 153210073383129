import { useLocation } from "react-router-dom";
import React from "react";

const Layout_SEO = ({ first = false, children }) => {
  const location = useLocation();

  const generateTitle = (path) => {
    if (!path) return "Certification Generator"; // Provide a default title in case path is undefined

    return path
      .slice(1)
      .split("-")
      .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))
      .join(" ")
      .split("/")[first ? 0 : 1] || "Home";
  };

  const title = generateTitle(location.pathname)[0].toUpperCase() + generateTitle(location.pathname).slice(1);
  return (
    <>
      <title>Certification Generator {title ? "- " + title : ""}</title>
      {children}
    </>
  );
};

export default Layout_SEO;
