import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AuthEntry = () => {
  const history = useHistory();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen gradientttt">
      <div className="bg-white dark:bg-gray-800 shadow-2xl rounded-3xl flex flex-col md:flex-row w-full max-w-5xl overflow-hidden">
        {/* Organization Section */}
        <div className="flex-1 p-8 md:p-10 flex flex-col items-center relative">
          <div className=" top-4 right-4 p-4 mb-4 bg-pink-100 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#BB271A"
              className="w-8 h-8 text-pink-600"
            >
              <path d="M80-120v-720h400v160h400v560H80Zm80-80h240v-80H160v80Zm0-160h240v-80H160v80Zm0-160h240v-80H160v80Zm0-160h240v-80H160v80Zm320 480h320v-400H480v400Zm80-240v-80h160v80H560Zm0 160v-80h160v80H560Z" />
            </svg>
          </div>
          <h2 className="text-3xl font-bold text-gray-800 dark:text-white mb-4 text-center">Organization Login</h2>
          <p className="text-gray-600 dark:text-gray-400 text-center mb-6">
            Manage your organization's events, users, and certificates with ease.
          </p>
          <button
            className="px-8 py-3 bg-pink-600 text-white text-lg rounded-lg hover:bg-pink-700 transition-transform transform hover:scale-105"
            style={{
              transition: "transform 0.15s ease-in-out",
            }}
            onClick={() => history.push("/login/org")}
          >
            Login as Organization
          </button>
          {/* <div className="mt-4 bottom-4 text-gray-500 dark:text-gray-400 text-sm">
            Need help?{" "}
            <a href="#help" className="text-pink-600 hover:underline">
              Contact Support
            </a>
          </div> */}
        </div>

        {/* Vertical Divider */}
        <div className="md:hidden">
          <hr className="border-gray-300 dark:border-gray-600" />
        </div>
        <div style={{ width: "1px", backgroundColor: "#c1c1c1" }} className="hidden md:block"></div>

        {/* User Section */}
        <div className="flex-1 p-8 md:p-10 flex flex-col items-center relative">
          <div className=" top-4 left-4 p-4 mb-4 bg-purple-100 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="currentColor"
              className="w-8 h-8 text-purple-600"
            >
              <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
            </svg>
          </div>
          <h2 className="text-3xl font-bold text-gray-800 dark:text-white mb-4 text-center">User Login / Register</h2>
          <p className="text-gray-600 dark:text-gray-400 text-center mb-6">
            Access your personalised dashboard or create a new account.
          </p>
          <div className="flex flex-col md:flex-row gap-4">
            <button
              className="px-6 py-3 bg-purple-600 text-white text-lg rounded-lg hover:bg-purple-700 transition-transform transform hover:scale-105"
              onClick={() => history.push("/login/user")}
              style={{
                transition: "transform 0.15s ease-in-out",
              }}
            >
              Login
            </button>
            <button
              className="px-6 py-3 border-2 border-purple-600 text-purple-600 text-lg rounded-lg hover:bg-purple-100 dark:hover:bg-gray-700 transition-transform transform hover:scale-105"
              style={{
                transition: "transform 0.15s ease-in-out",
              }}
              onClick={() => history.push("/register/user")}
            >
              Register
            </button>
          </div>
          {/* <div className="mt-4 bottom-4 text-gray-500 dark:text-gray-400 text-sm">
            Having issues?{" "}
            <a href="#help" className="text-purple-600 hover:underline">
              Get Support
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AuthEntry;
